.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-container {
  width: 100% !important;
  height: 92vh ;
}

.react-chatbot-kit-chat-header {
  background-color: #108069!important;
  font-size: 18px !important;
  color: #ffffff !important;
  padding: 20px!important;
}

.react-chatbot-kit-chat-inner-container, .react-chatbot-kit-chat-message-container {
  height: 92% !important;
  background-color: #efe6de !important;
}

.react-chatbot-kit-chat-bot-message {
  border-radius: 13px !important;
  border-top-left-radius: 0 !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 1px 1px rgb(215 215 215);
  color: black !important;
  font-size: 18px !important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  align-items: center;
  min-height: 28px;
  width: 80%!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-top: 0 solid transparent!important;
  border-bottom: 14px solid transparent!important;
  border-right: 14px solid #ffffff!important;
  left: -11px!important;
  top: 0!important;
}

.react-chatbot-kit-user-chat-message-arrow {
  border-top: 0 solid transparent!important;
  border-bottom: 14px solid transparent!important;
  border-left: 14px solid #e8ffde!important;
  right: -11px!important;
  top: 0!important;
}

.react-chatbot-kit-user-chat-message {
  border-radius: 13px !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0 0 1px 1px rgb(215 215 215);
  background-color: #e8ffde!important;
  color: black !important;
  font-size: 18px !important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.react-chatbot-kit-user-chat-message-container .react-chatbot-kit-user-avatar {
  display: none;
}

.react-chatbot-kit-chat-bot-message span {
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  animation: typing 1.8s steps(50, end);
}

.react-chatbot-kit-chat-input-form {
  margin: auto;
  width: 85% !important;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 30px;
  flex-direction: row-reverse;
}

.react-chatbot-kit-chat-input {
  border-top: initial!important;
  outline: none;
  font-size: 18px !important;
  padding: 8px 0 !important;
}

.react-chatbot-kit-chat-btn-send {
  background-color: transparent !important;
  box-shadow: 0 0 !important;
  width: 50px !important;
  margin-right: 10px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-chatbot-kit-chat-btn-send svg {
  fill: #808B9F !important;
  width: 22px;
}
